import React from "react";
import OrderForm from "./components/OrderForm";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <Header />
      <OrderForm />
    </div>
  );
}

export default App;
