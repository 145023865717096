import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAedySdpNEVE82WM6k8qgbHZax9KUDxHWM",
  authDomain: "recreations-74ed3.firebaseapp.com",
  projectId: "recreations-74ed3",
  storageBucket: "recreations-74ed3.appspot.com",
  messagingSenderId: "14266208271",
  appId: "1:14266208271:web:05f1d6c91ba3739279d37a",
  measurementId: "G-PPPHFEB7WW",
};

const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
