import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // 白色のAppBar
    },
  },
});

const TopBar = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img
              src="/images/logo.png"
              alt="みんなのレクリエーション"
              style={{ height: "40px", width: "auto" }}
            />
          </Box>
          <Button color="inherit" href="https://www.recreations.jp/">
            サービス
          </Button>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

const CenteredLogo = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: 2,
        bgcolor: "background.paper",
      }}
    >
      <img
        src="/images/logo.png"
        alt="みんなのレクリエーション"
        style={{ maxHeight: "96px", width: "auto" }}
      />
    </Box>
  );
};

const Header = () => {
  return (
    <header>
      <TopBar />
      <CenteredLogo />
    </header>
  );
};

export default Header;
