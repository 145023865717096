import React, { useState, useMemo, useCallback } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Paper,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

const FormField = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}));

const FieldTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const LargeButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  fontSize: "1.1rem",
}));

const PRICE_PER_UNIT = 3300;

const OrderForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    postcode: "",
    address: "",
    contactPerson: "",
    email: "",
    quantity: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const totalPrice = useMemo(() => {
    const quantity = parseInt(formData.quantity) || 0;
    return quantity * PRICE_PER_UNIT;
  }, [formData.quantity]);

  const steps = ["注文情報入力", "確認", "完了"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };
  const lookupAddress = useCallback(async (postcode) => {
    try {
      const response = await fetch(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postcode}`
      );
      const data = await response.json();
      if (data.results) {
        const { address1, address2, address3 } = data.results[0];
        const fullAddress = `${address1}${address2}${address3}`;
        setFormData((prevData) => ({
          ...prevData,
          address:
            prevData.address.trim() === "" ? fullAddress : prevData.address,
        }));
      }
    } catch (error) {
      console.error("郵便番号検索エラー:", error);
    }
  }, []);

  const handlePostcodeBlur = (e) => {
    const postcode = e.target.value.replace(/-/g, "");
    if (postcode.length === 7) {
      lookupAddress(postcode);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.companyName.trim()) errors.companyName = "法人名は必須です";
    if (!formData.postcode.trim()) errors.postcode = "郵便番号は必須です";
    if (!formData.address.trim()) errors.address = "住所は必須です";
    if (!formData.contactPerson.trim())
      errors.contactPerson = "担当者名は必須です";
    if (!formData.email.trim()) {
      errors.email = "メールアドレスは必須です";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "有効なメールアドレスを入力してください";
    }
    if (!formData.quantity || parseInt(formData.quantity) < 1) {
      errors.quantity = "1以上の数量を入力してください";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const submitOrder = httpsCallable(functions, "submitOrder");
      const result = await submitOrder({ ...formData, totalPrice });
      if (result.data.success) {
        setOrderId(result.data.orderId);
        setActiveStep(2);
      } else {
        setError("注文の送信に失敗しました。もう一度お試しください。");
      }
    } catch (error) {
      console.error("Error submitting order:", error);
      setError("エラーが発生しました。もう一度お試しください。");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setActiveStep(1);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <FormField elevation={0}>
          <Typography variant="h4" component="h1" gutterBottom>
            注文フォーム
          </Typography>
          <Typography variant="h6" gutterBottom>
            注文の流れ
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="1. 注文フォームより注文" />
            </ListItem>
            <ListItem>
              <ListItemText primary="2. 弊社からライセンスコード及び請求書を送付" />
            </ListItem>
            <ListItem>
              <ListItemText primary="3. デバイスにてライセンスコードを読み取ってください(マニュアル)" />
            </ListItem>
          </List>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </FormField>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {activeStep === 0 && (
          <form onSubmit={handleConfirm}>
            <FormField elevation={0}>
              <FieldTitle variant="subtitle1">
                請求先情報を入力してください。
              </FieldTitle>
              <TextField
                fullWidth
                label="法人名"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                variant="outlined"
                required
                margin="normal"
                error={!!formErrors.companyName}
                helperText={formErrors.companyName}
              />
              <TextField
                fullWidth
                label="郵便番号"
                name="postcode"
                value={formData.postcode}
                onChange={handleChange}
                onBlur={handlePostcodeBlur}
                variant="outlined"
                required
                margin="normal"
                error={!!formErrors.postcode}
                helperText={formErrors.postcode}
              />
              <TextField
                fullWidth
                label="住所"
                name="address"
                value={formData.address}
                onChange={handleChange}
                variant="outlined"
                required
                margin="normal"
                error={!!formErrors.address}
                helperText={formErrors.address}
              />
              <TextField
                fullWidth
                label="担当者名"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleChange}
                variant="outlined"
                required
                margin="normal"
                error={!!formErrors.contactPerson}
                helperText={formErrors.contactPerson}
              />
              <TextField
                fullWidth
                label="メールアドレス"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                required
                margin="normal"
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </FormField>
            <FormField elevation={0}>
              <FieldTitle variant="subtitle1">使用するデバイスの数</FieldTitle>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                1ライセンス1年間で3,300円(税込み)
              </Typography>
              <TextField
                fullWidth
                label="注文数"
                name="quantity"
                type="number"
                value={formData.quantity}
                onChange={handleChange}
                variant="outlined"
                required
                margin="normal"
                inputProps={{ min: "1" }}
                error={!!formErrors.quantity}
                helperText={formErrors.quantity}
              />
              {formData.quantity && (
                <Typography variant="h6" sx={{ mt: 2 }}>
                  請求金額: {totalPrice.toLocaleString()}円
                </Typography>
              )}
            </FormField>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LargeButton variant="contained" color="primary" type="submit">
                確認
              </LargeButton>
            </Box>
          </form>
        )}
        {activeStep === 1 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              注文内容の確認
            </Typography>
            <FormField elevation={0}>
              <FieldTitle variant="subtitle1">請求先情報</FieldTitle>
              <Typography>法人名: {formData.companyName}</Typography>
              <Typography>郵便番号: {formData.postcode}</Typography>
              <Typography>住所: {formData.address}</Typography>
              <Typography>担当者名: {formData.contactPerson}</Typography>
              <Typography>メールアドレス: {formData.email}</Typography>
            </FormField>
            <FormField elevation={0}>
              <FieldTitle variant="subtitle1">使用するデバイスの数</FieldTitle>
              <Typography>注文数: {formData.quantity}</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                1ライセンス1年間で3,300円(税込み)
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                請求金額: {totalPrice.toLocaleString()}円
              </Typography>
            </FormField>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LargeButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                sx={{ mr: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : "送信"}
              </LargeButton>
              <LargeButton variant="outlined" onClick={() => setActiveStep(0)}>
                戻る
              </LargeButton>
            </Box>
          </Box>
        )}
        {activeStep === 2 && (
          <FormField elevation={0}>
            <Typography variant="h6" gutterBottom>
              注文が完了しました
            </Typography>
            <Typography>ご注文ありがとうございます。</Typography>
            <Typography>注文ID: {orderId}</Typography>
            <Typography>
              確認メールをお送りしました。ご確認ください。
            </Typography>
          </FormField>
        )}
      </Box>
    </Container>
  );
};

export default OrderForm;
